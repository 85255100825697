/* Hide right scrollbar for all browsers */
::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

html {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }

/* least specificity */
* {
    box-sizing: border-box
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* median specificity */
.fade {
    position: relative;
    width: 100%;
    height: 100dvh;
    background:
        url('../assets/map.png') no-repeat center center,
        linear-gradient(135deg, #FFFFFF, #538BC6);
    background-size: cover;
    background-blend-mode: hard-light;
    overflow-x: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;
    height: 100dvh;
    padding: 3rem 4rem;
    overflow-y: scroll;
}

.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 1;
}

.content {
    display: flex;
    flex-direction: column;
    width: 440px;
    justify-content: flex-start;
}

.feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.footer {
    position: fixed;
    bottom: 3rem;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}

.logo {
    display: flex;
    align-items: center;
    z-index: 10;
}

.phone {
    position: absolute;
    top: 26%;
    right: 390px;
    height: 535px;
    width: 262px;
    border-radius: 40px;
    border: 4px solid black;
    background:
        url('../assets/PhonePreviewImage.jpg');
    background-size: cover;
    box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.dashboard {
    position: absolute;
    top: 20%;
    right: -35px;
    width: 578px;
    height: 536px;
    background: 
        url('../assets/dashboardPreview.png');
    background-size: cover;
    border: 3px solid rgb(255, 255, 255);
    box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.button-base {
    padding: 12px 20px;
    border-radius: 25px;
    text-transform: capitalize;
    background-color: #FFFFFF;
    border: none;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: 550;
}

.sticky-footer {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 0;
    position: fixed;
    background-color: #FFFFFF;
    opacity: 90%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: 0px -6px 20px 0px rgba(0, 0, 0, 0.1);
    z-index: 2;
    animation: slideFadeIn 1s ease-out forwards;
}

/* highest specificity */
.button-primary {
    background-color: #0B2355;
    color: #FFFFFF;
}

.button-secondary {
    background-color: #FFFFFF;
    color: #0B2355;
}

.button-outlined {
    padding: 10px 20px;
    color: #0B2355;
    border: solid 2px #0B2355;
    box-shadow: none;
    background-color: #FFFFFF;
}

.button-header {
    margin-left: auto;
}

.footer-text {
    font-size: 12px;
    font-weight: 500;
    color: #0B2355;
}

.content h1 {
    font-size: 52px;
    color: #0B2355;
    margin-bottom: 10px;
}

.content p {
    font-size: 18px;
    font-weight: 500;
    color: #435366;
}

.content-buttons {
    display: flex;
    gap: 1.5rem;
    margin-top: 40px;
}

.content-terms {
    display: flex;
    flex-direction: column;
    width: 60%;
    min-width: 350px;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding-bottom: 6rem;
}

.content-terms h1 {
    font-size: 32px;
    font-weight: 700;
    color: #0B2355;
    margin-bottom: 3rem;
}

.feature p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 5px;
}

.terms p {
    font-size: 16px;
    font-weight: 500;
    color: #808182;
}

.terms-date p {
    margin: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #538BC6;
    text-transform: uppercase;
}

.sticky-content {
    text-align: center;
    padding: 1rem;
    width: calc(100% - 20rem);
}

.sticky-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.sticky-footer strong {
    font-size: 20px;
}

.hover-logo:hover {
    cursor: pointer;
}

.fade-terms:before {
    content: '';
    width: 100%;
    height: 100dvh;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 80dvh, white, white);
}

.button-primary:hover {
    cursor: pointer;
}

.button-secondary:hover {
    cursor: pointer;
}

.button-outlined:hover {
    cursor: pointer;
}

/* custom styles */
.hidden {
    display: none;
}

/* keyframes */
@keyframes slideFadeIn {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 1145px) {
    .phone {
        visibility: hidden;
    }

    .dashboard {
        visibility: hidden;
    }
}

/* Styles for tablets and smaller screens */
@media (max-width: 1024px) {

    .container {
        padding: 2rem 2rem;
    }

    .content-terms {
        width: 100%;
        min-width: 350px;
        max-width: 750px;
    }

    .sticky-content {
        width: calc(100% - 1.5rem);
    }
}

/* Styles for mobile devices */
@media (max-width: 480px) {
    .fade {
        background:
            linear-gradient(180deg, #FFFFFF, #C5E8FC);
    }

    .container {
        gap: 1rem;
        overflow-x: hidden;
    }

    .header {
        margin-bottom: 0px;
    }

    .content {
        width: 340px;
    }

    .footer {
        bottom: 2rem;
      }

    .button-base {
        font-size: 14px;
        padding: 12px 30px;
    }

    .content h1 {
        font-size: 45px;
        margin-bottom: 0px;
    }

    .content p {
        font-size: 20px;
        width: 280px;
    }

    .content-buttons {
        margin-top: 30px;
        width: 600px;
    }

    .content-terms {
        min-width: 300px;
    }

    .feature p {
        font-size: 18px;
      }

    .sticky-content {
        font-size: 12px;
        width: calc(100% - 1rem);
    }

    .sticky-footer strong {
        font-size: 16px;
    }

    .custom-list { 
        padding-left: 0px;
    }
  }


@media (max-height: 685px) {
    .footer {
        position: static;
    }
}
